import {jwtDecode} from 'jwt-decode';
import {IdToken, Auth} from '../../infrastructure/types/global';
import { isAuthenticatedUserAuthorized} from "../../infrastructure/repositories/AuthRepository";
import {COOKIE_ACCESS_TOKEN, COOKIE_ID_TOKEN, getCookie, removeCookie} from "../../infrastructure/persistence/CookieAdapter";
import { isDefined } from '../../utils/type-utils';

export const isUserAuthenticatedAndAuthorized = async(): Promise<Auth> => {
    const accessToken = getCookie(COOKIE_ACCESS_TOKEN);
    const idToken = getCookie(COOKIE_ID_TOKEN);

    const decodedToken = idToken ? jwtDecode<IdToken>(idToken) : null;
    let isAuthenticated = false;
    let isAuthorized = false;

    if (decodedToken && decodedToken.exp) {
        isAuthenticated = new Date() <= new Date(decodedToken.exp * 1000);
    }
    if (isAuthenticated && accessToken) {
        isAuthorized = await isUserAuthorized(accessToken);
    }

    return {
        isAuthenticated,
        accessToken,
        idToken: decodedToken,
        isAuthorized
    };
}

export const logout = (accessToken?: string | null) => {
    removeCookie(COOKIE_ACCESS_TOKEN);
    removeCookie(COOKIE_ID_TOKEN);
    
    if (isDefined(accessToken)) {
        localStorage.removeItem(`auth_${accessToken}`);
    }
}

const isUserAuthorized = (accessToken: string): Promise<boolean> => {
    return isAuthenticatedUserAuthorized(accessToken);
}
