import { FC } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { theme } from "../../../../theme";
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';

const UserDetailsNotFound:FC = () => {

  return (
    <>
        <Box sx={{p: theme.spacing(3), flexDirection: 'row', display: 'flex', justifyContent: 'space-between'}}>
          <Typography fontWeight="bold">Result</Typography>
        </Box>
        <Divider sx={{marginBottom: theme.spacing(2)}} />
        <Box sx={{p: theme.spacing(3), textAlign: 'center', height: '80vh', pt: theme.spacing(25)}}>
            <AssignmentLateOutlinedIcon sx={{fontSize: '150px'}}/>
            <Typography fontSize={theme.typography.h4.fontSize} sx={{mb: theme.spacing(3)}}>No result found</Typography>
            <Typography>You may search again or check for any possible typos.</Typography>
        </Box>
      </>
  );
}


export default UserDetailsNotFound;