import React from "react";

const HomePage = () => {
    return (
        <div className="App">
            <header className="App-header">
                <img src="./logo.png" className="App-logo" alt="logo" />
                <h1>Welcome to Vestiaire Collective Chat Moderation tool</h1>
            </header>
        </div>
    );
}

export default HomePage;