export enum searchType {
    USER_ID = 'USER_ID',
    EMAIL = 'EMAIL',
    ERROR = 'ERROR',
}

export type SearchTypeType = keyof typeof searchType;

export const typeToSearch = (value?: string): SearchTypeType => {
    if (!value) {
        return searchType.ERROR;
    }
    if (/^\d+$/.test(value)) {
        return searchType.USER_ID;
    }
    if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        return searchType.EMAIL;
    }
    return searchType.ERROR;
}