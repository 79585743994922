import { Box, Button, Drawer, List, ListItem, styled, Typography } from "@mui/material";
import { FC } from "react";
import UserCard from "../UserCard/UserCard";
import SearchBar from "../SearchBar/SearchBar";
import { theme } from "../../../../theme";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../application/context/AuthContext";
import { isDefined } from "../../../../utils/type-utils";

type SideBarProps = {
  open: boolean;
  drawerWidth: number;
}

const SideBar:FC<SideBarProps> = ({ open, drawerWidth }) => {

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    paddingBottom: 0
  }));

  const {auth, loginAction} = useAuth();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        }
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        {(auth?.isAuthenticated || isDefined(auth?.accessToken))
          ? 
          <UserCard
            src=''
            sx={{backgroundColor: 'white', borderRadius: '5px', padding: theme.spacing(3)}}
            topLineText={auth?.idToken?.given_name}
            bottomLineText={auth?.idToken?.family_name}
            subMenu={true}
          />
          : 
          <Button variant="outlined" onClick={() => loginAction()}>Login</Button>
        }
        <SearchBar sx={{marginTop: theme.spacing(2)}} />
      </DrawerHeader>
      <Box sx={{padding: theme.spacing(4)}}>
        <Typography
          sx={{
            fontSize:theme.typography.body2.fontSize,
            color: theme.palette.textBlackColor.light
          }}
        >
          Users
        </Typography>
        <List>
          <ListItem><Link to={"/users"}>Users List</Link></ListItem>
        </List>
      </Box>
    </Drawer>
  );
}


export default SideBar;