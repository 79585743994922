import React from "react";

const UsersPage = () => {
    return (
        <div className="UsersPage">
            Welcome to the users list
        </div>
    );
}

export default UsersPage;