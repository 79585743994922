import { isUserAuthorized } from "../api/PapiClient";

export const isAuthenticatedUserAuthorized = async(accessToken: string): Promise<boolean> => {
    if (accessToken) {
        const cacheKey = `auth_${accessToken}`;
        const cachedResult = localStorage.getItem(cacheKey);
        if (cachedResult && cachedResult === "true") {
            return true;
        }
    }

    const isAuthorized = await isUserAuthorized(accessToken);
    localStorage.setItem(`auth_${accessToken}`, isAuthorized.toString());
    return isAuthorized;
}
