import { createContext, useContext, useEffect, useState } from 'react';
import { Auth, FCWithChildren } from '../../infrastructure/types/global';
import { execute } from '../../core/usecases/verifyUserAuth';
import { logout } from '../services/AuthService';
import { isDefined } from '../../utils/type-utils';

export type AuthContextProps = {
	auth: Auth | undefined;
	isLoading: boolean;
	loginAction: Function;
	logOutAction: Function;
}

const AuthContext = createContext<AuthContextProps>({} as never);

export const AuthProvider: FCWithChildren = ({children}): React.JSX.Element => {
    const [auth, setAuth] = useState<Auth>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
      	execute().then((authReponse) => {
			setAuth(authReponse);
			setIsLoading(false);
		});
  	}, []);

    const loginAction = () => {
		if (!isDefined(process.env.REACT_APP_BACKOFFICE_URL)) {
			throw new Error('REACT_APP_BACKOFFICE_URL is not defined');
		}

		window.location.href = process.env.REACT_APP_BACKOFFICE_URL;
    };

    const logOutAction = () => {
		setAuth(undefined);
		logout();
    };

	const value = {
		auth,
		isLoading,
		logOutAction,
		loginAction
	}

    return <AuthContext.Provider value={value}> {children} </AuthContext.Provider>
}
  

export const useAuth = () => {
    return useContext(AuthContext);
};


