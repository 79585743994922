import { useParams } from "react-router-dom";
import UserDetails from "../components/Layout/UserDetails/UserDetails";
import { Box } from "@mui/material";

const UserProfilePage = () => {
    let { userId } = useParams();
    return (
        <Box className="UserProfilePage">
            <UserDetails userToSearch={userId} />
        </Box>
    );
}

export default UserProfilePage;