import MainSection, { MainSectionProps } from '../../../ui/components/Layout/MainSection/MainSection';
import { FCWithChildren } from '../../../infrastructure/types/global';
import { Error } from '../../../helpers/error';
import { Box } from '@mui/material';
import SideBar from './SideBar/SideBar';
import { useAuth } from '../../../application/context/AuthContext';
import { isDefined } from '../../../utils/type-utils';
const drawerWidth = 320;

type ChatModLayoutProps = {
    mainSectionProps?: MainSectionProps;
}

const ChatModLayout:FCWithChildren<ChatModLayoutProps> = ({
      mainSectionProps,
      children
  }) => {
    const { auth, isLoading } = useAuth();

    return (
      <>
        {isLoading ? 
          <Box sx={{ paddingTop: 15 }}> Loading Auth stuffs</Box>
          :
          !auth?.isAuthenticated || !isDefined(auth?.accessToken) ?
              <Box sx={{ paddingTop: 15 }}>
                <SideBar open={true} drawerWidth={drawerWidth} />
                <Error code={403} />
              </Box>
             : 
              <>
                <SideBar open={true} drawerWidth={drawerWidth} />
                <MainSection {...mainSectionProps} open={true} drawerWidth={drawerWidth}>{children}</MainSection>
              </>
        }
      </>
    )
}

export default ChatModLayout