export type Nullable<T> = T | null;

export type Optional<T> = Nullable<T> | undefined;

export const isDefined = <T>(value: T | undefined | null): value is T => value !== undefined && value !== null;

export function isNotEmptyString(value: Optional<string>): value is string {
  return typeof value === 'string' && value.length > 0;
}

export function isEmptyString(value: Optional<string>): boolean {
  return typeof value !== 'string' || value.length === 0;
}
