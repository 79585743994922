import {FC, useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {Box, Button, Divider, Typography} from "@mui/material";
import UserDetailsTable from "./UserDetailsTab";
import UserCard from "../UserCard/UserCard";
import {theme} from "../../../../theme";
import UserDetailsNotFound from "./UserDetailsNotFound";
import {isDefined} from "../../../../utils/type-utils";
import {User} from "../../../../core/entities/User";
import {getUserByEmailOrId} from "../../../../core/usecases/getUserByEmailOrId";

type UserDetailsProps = {
    userToSearch?: string;
}

const UserDetails: FC<UserDetailsProps> = ({userToSearch}) => {
    const [user, setUser] = useState<User>();
    const {isSuccess, isError, isPending, mutate} = useMutation({
        mutationFn: () => getUserByEmailOrId(userToSearch),
        onSuccess: (a) => {
            setUser(a);
        },
        onError: (a) => {
            console.error(a)
        },
    });

    useEffect(() => {
        mutate();
    }, [mutate, userToSearch])

    return (
        <>
            {
                isPending && <Typography>is loading</ Typography>
            }
            {
                isError && <UserDetailsNotFound/>
            }
            {
                (isSuccess && isDefined(user)) &&
                <>
                    <Box sx={{
                        p: theme.spacing(3),
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <UserCard
                            src={user.profilePictureUrl}
                            topLineText={`User: ${user.id}`}
                            bottomLineText={user.email}
                        />
                        {
                            user.isBanned ?
                                <Button variant="outlined">Unban</Button> :
                                <Button variant="outlined" color="error">Ban</Button>
                        }
                    </Box>
                    <Divider sx={{marginBottom: theme.spacing(2)}}/>
                    <Box sx={{p: theme.spacing(3)}}>
                        <Typography sx={{textDecoration: "underline", fontWeight: "Bold"}}>
                            <span style={{cursor: "pointer"}}>Personal info</span>
                        </Typography>
                    </Box>
                    <Box sx={{p: theme.spacing(3)}}>
                        <UserDetailsTable userInfos={user}></UserDetailsTable>
                    </Box>
                </>
            }
        </>
    );
}


export default UserDetails;