import { User } from "../../core/entities/User";
import { getUserByEmail, getUserById } from "../../infrastructure/repositories/UserRepository";
import { COOKIE_ACCESS_TOKEN, getCookie } from "../../infrastructure/persistence/CookieAdapter";
import { searchType, typeToSearch } from "../../utils/search-utils";
import { isDefined } from "../../utils/type-utils";

export const getUser = async (value?: string): Promise<User> => {
    if (!isDefined(value)) {
        throw new Error('Invalid value');
    }

    const accessToken = getCookie(COOKIE_ACCESS_TOKEN);
    const searchEmail = (typeToSearch(value) === searchType.EMAIL);

    let response = (searchEmail) ? getUserByEmail(value, accessToken) : getUserById(value, accessToken);

    return response;
}


