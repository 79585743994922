import {Paper, Typography} from '@mui/material';

export interface ErrorProps {
    code?: number;
}

export function Error({code = 500}: ErrorProps) {
    let message: JSX.Element;

    switch (code) {
        case 403:
        case 401:
            message = (
                <>
                    <Typography variant='h6' paragraph={true}>
                        Access denied!
                    </Typography>

                    <Typography variant='body2'>
                        Your session has expired or you are not authorized to access this page.
                    </Typography>
                </>
            );
            break;
        case 404:
            message = (
                <>
                    <Typography variant='h6' paragraph={true}>
                        Page not found!
                    </Typography>

                    <Typography variant='body2'>
                        Sorry, we couldn’t find the page you’re looking for.
                        Perhaps you’ve mistyped the URL? Be sure to check your spelling.
                    </Typography>
                </>
            );
            break;
        default:
            message = (
                <>
                    <Typography variant='h6' paragraph={true}>
                        Something went wrong!
                    </Typography>

                    <Typography variant='body2'>
                        An unknown error occurred while loading this page.
                    </Typography>
                </>
            );

    }

    return (
        <Paper
            sx={{
                textAlign: 'center',
                py: 3,
                ml: '320px'
            }}
        >
            {message}
        </Paper>
    );
}
